import styled from 'styled-components';
import breakpoints from 'constants/breakpoints';
import theme from 'constants/theme';

export const Container = styled.div`
  padding: 2rem ${theme.gutter.desktop};
  max-width: 420px;

  @media (max-width: ${breakpoints.TABLET}px) {
    padding: 1rem ${theme.gutter.mobile};
  }
`;

export const HomepageContainer = styled.div`
  padding: 2rem ${theme.gutter.desktop};
  max-width: 640px;

  @media (max-width: ${breakpoints.TABLET}px) {
    padding: 1rem ${theme.gutter.mobile};
  }
`;
